<template>
  <a-modal :visible="visible" title="添加车型" destroyOnClose :maskClosable="false"  @ok="handleOk()" @cancel="handleCancel">
      <a-form
        ref="form"
        :model="formState"
        name="form"
        :validateOnRuleChange="false"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
        autocomplete="off"
        scrollToFirstError
      >
        <a-form-item
          label="车型"
          name="name"
          :rules="[{ required: true, message: '输入车型名称' }]"
        >
          <a-input v-model:value="formState.name" name="name" />
        </a-form-item>
        <a-form-item
          label="车图"
          name="imageId"
        >
          <GigaUpload v-model:value="formState.imageId" name="imageId" :imageUrl="formState.image" @setImg="setImg($event, 'image')"  />
        </a-form-item>
        <a-form-item
          label="版本"
          name="category"
        >
          <a-input v-model:value="formState.category" name="category" />
        </a-form-item>
        <a-form-item
          label="排序"
          name="displayOrder"
        >
          <a-input v-model:value="formState.displayOrder" name="displayOrder" />
        </a-form-item>
      </a-form>
    </a-modal>
</template>
<script>
import { ref, watch } from 'vue';
import GigaUpload from '../GigaUpload.vue'
export default {
  name: 'VehicleForm',
  components: {
    GigaUpload
  },
  props: ['visible', 'formData'],
  setup(props) {
    const formState = ref(props.formData)
    watch(() => props.formData, (newVal) => formState.value = newVal)
    return {
      formState,
      form: ref(null),
    };
  },
  
  methods: {
    setImg(e, type) {
      let img = e[0]
      let formState = this.formState
      formState[`${type}Id`] = img.id
      formState[type] = img.url
      this.formState = JSON.parse(JSON.stringify(formState))
    },
    handleOk() {
      this.form.validateFields().then(res => {
        res.displayOrder = res.displayOrder ? parseInt(res.displayOrder) : 0
        let formState = Object.assign(this.formState, res)
        this.$emit('formOk', formState)
      }).catch(err => {
        console.error(err)
        this.$message.error(err.errorFields[0].errors[0])
      })
    },
    handleCancel() {
      this.$emit('formCancel')
    },
  },

}
</script>